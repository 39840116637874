<template>
  <div class="dash-pro-item mb-30 dashboard-widget imprimir">
    <div class="text-center">
      <img :src="logo" width="220" class="mb-5" />
    </div>
    <div class="header">
      <h4 class="title">Comprovante do agendamento</h4>
      <a
        javascript:void(0)
        @click="imprimir"
        title="Imprimir"
        class="printer-btn"
        ><i class="fa fa-print"></i
      ></a>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-3">
            <span class="destaque">Protocolo: </span>
          </div>
          <div class="col-9">
            <span class="destaque">{{ agendamento.agendamentoId }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Leilão: </span>
          </div>
          <div class="col-9">
            <span>{{ agendamento.leilao }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Lote: </span>
          </div>
          <div class="col-9">
            <span>{{ agendamento.numeroLote }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Descrição: </span>
          </div>
          <div class="col-9">
            <span>{{ agendamento.descricaoLote }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Local: </span>
          </div>
          <div class="col-9">
            <span>{{ agendamento.localEndereco }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <span>Data: </span>
          </div>
          <div class="col-9">
            <span>{{ agendamento.dataAgendamento }}</span>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12">
            <div class="separador"></div>
            <p>Atenção!</p>
            <p>
              Verifique a documentação exigida no edital, parágrafo 'RETIRADA
              DOS LOTES'.
            </p>
          </div>
        </div>

        <div class="col-12">
          <div class="alert alert-danger" role="alert" v-if="mensagemErro">
            {{ mensagemErro }}
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3 imprimir-btn">
      <div class="col-12 text-center mt-2">
        <Button
          desc="Voltar"
          :width="45"
          :disabled="loading"
          tela="lotes"
          class="mr-2"
        ></Button>
        <Button
          :desc="width < 800 ? 'cancelar' : 'cancelar agendamento'"
          :width="45"
          :click="cancelar"
          :loading="loading"
          :disabled="loading"
        ></Button>
      </div>
      <div class="col-12 col-md-6"></div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Button from './components/AgButton.vue';
import Notification from '@/mixins/notification';
export default {
  name: 'ResumoView',
  components: {
    Button,
  },
  mixins: [Notification],
  data: () => ({
    loading: false,
    mensagemErro: null,
    agendamento: {},
    width: window.innerWidth,
    logo: process.env.VUE_APP_LOGO_PATH,
  }),
  computed: {
    ...mapGetters(['getAgLote', 'getAgData', 'getUsuarioInfo', 'getAgHora']),
  },
  methods: {
    ...mapActions([
      'agendar',
      'info',
      'agendamentoPorId',
      'agendamentoCancelar',
    ]),
    ...mapMutations(['setAgTela']),
    async getAgendamento() {
      const { agendamentoId } = this.getAgLote;
      try {
        const resp = await this.agendamentoPorId(agendamentoId);
        this.agendamento = resp.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async cancelar() {
      try {
        this.showConfirm({
          type: 'warning',
          title: 'Tem certeza?',
          message: 'Deseja cancelar o agendamento',
        }).then(async (e) => {
          if (e.isConfirmed) {
            const { agendamentoId } = this.agendamento;
            await this.agendamentoCancelar(agendamentoId);
            this.showToast({ title: 'Agendamento cancelado ' });
            this.setAgTela('lotes');
          }
          console.log(e);
        });
      } catch (error) {
        this.mensagemErro = 'Não foi possível cancelar o agendamento.';
      }
    },
    imprimir() {
      window.print();
    },
    onResize() {
      this.width = window.innerWidth;
    },
  },
  filters: {
    formatDataHora(value) {
      return moment(value).utc().format('DD/MM/YYYY');
    },
  },
  mounted() {
    this.info();
    this.getAgendamento();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped>
.swal2-popup {
  font-size: 0.7rem;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
}

.detalhe p {
  font-size: 18px;
}

.detalhe p:nth-child(1) {
  font-size: 24px;
  color: #2baf49;
  font-weight: bolder;
}

.printer-btn {
  color: #616161;
  width: 40px;
  height: 40px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25%;
}

@media print {
  @page {
    margin: 0px;
  }
  html,
  body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    overflow: hidden;
  }

  body * {
    visibility: hidden;
  }

  .title {
    text-align: center;
    padding-bottom: 20px;
  }

  .globalClass_a045,
  .globalClass_a045 * {
    display: none;
  }

  .printer-btn,
  .imprimir-btn {
    display: none;
  }

  header {
    display: none;
  }
  .hero-section {
    display: none;
  }

  .imprimir,
  .imprimir * {
    visibility: visible;
  }

  .imprimir {
    margin: 0;
    padding: 0;
    --position: absolute;
    --left: 0;
    --top: 0;
  }
}
</style>
