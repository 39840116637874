<template>
  <div class="dash-pro-item mb-30 dashboard-widget">
    <div class="header">
      <h4 class="title">Lotes Arrematados</h4>
      <div class="combo">
        Leilão:<select v-model="leilao" class="form-control">
          <option value=""></option>
          <option v-for="leilao in getLeiloes" :key="leilao" :value="leilao">
            {{ leilao }}
          </option>
        </select>
      </div>
    </div>
    <div class="row" v-if="getLotesPorLeilao.length == 0">
      <div class="col-12 mt-3 text-center">
        <h6>Selecione o leilão para ver os lotes.</h6>
      </div>
    </div>
    <div class="row" v-else>
      <div
        class="col-12 col-lg-4 col-md-6 col-sm-6 mb-4 card-lote"
        v-for="lote in getLotesPorLeilao"
        :key="lote.loteId"
      >
        <CardLote
          :lote="lote"
          btn
          :tela="lote.statusPagamento == 'PAGO' ? 'agendar' : 'pagar'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CardLote from './components/CardLote.vue';
// import Button from "./components/AgButton.vue";

export default {
  name: 'Agendamento',
  components: {
    CardLote,
  },
  data: () => ({
    leilao: '',
  }),
  computed: {
    ...mapGetters(['getlotesArrematados', 'getLotesLoading', 'getAgLoteId']),
    getLeiloes() {
      if (!this.getlotesArrematados.data) return;
      return [
        ...new Set(
          this.getlotesArrematados.data.map((x) => x.leilao.toUpperCase())
        ),
      ];
    },
    getLotesPorLeilao() {
      if (!this.getlotesArrematados.data) return;
      return this.getlotesArrematados.data.filter(
        (x) => x.leilao.toUpperCase() == this.leilao
      );
    },
  },
  methods: {
    ...mapActions(['setlotesArrematados']),
    ...mapMutations(['setAgLoteId', 'setAgLote', 'setAgTela']),
    select(lote) {
      this.setAgLoteId(lote.loteId);
      this.setAgLote(lote);
    },
    async inicializar() {
      await this.setlotesArrematados({
        pageSize: 0,
        pageNumber: 1,
      });
    },
  },
  created() {
    this.inicializar();
  },
  mounted() {
    // document.getElementsByClassName("VueCarousel-inner")[0].style.height =
    //   "500px";
  },
};
</script>

<style scoped>
.titulo {
  color: #171d1c;
}
.slide {
  max-height: 500px;
  background-color: #fff;
}
.selecionado {
  border: solid 2px #4cb848;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header .title {
  width: auto !important;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  .combo {
    width: 100%;
  }
}
</style>
