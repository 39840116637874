var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loteComp)?_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-1 card-main"},[_c('h6',{staticClass:"titulo"},[_vm._v("LOTE "+_vm._s(_vm.loteComp.numeroLote))]),_c('div',[_c('p',{staticClass:"descricao badge",class:_vm.loteComp.statusPagamento == 'PAGO'
            ? 'badge-success'
            : !_vm.loteComp.faturaToken
            ? 'badge-info'
            : 'badge-warning'},[_vm._v(" "+_vm._s(_vm.loteComp.statusPagamento == 'PAGO' ? 'PAGO' : _vm.loteComp.faturaToken ? 'PENDENTE' : 'PROCESSANDO')+" ")])])]),_c('div',{staticClass:"card-img"},[_c('img',{attrs:{"src":_vm.loteComp && _vm.loteComp.foto.arquivo.url,"title":_vm.loteComp && _vm.loteComp.descricao}})]),_c('div',{staticClass:"row card-main"},[_c('div',{staticClass:"col-12 text-left"},[_c('p',{staticClass:"descricao"},[_vm._v(_vm._s(_vm.loteComp.descricao))])]),_c('div',{staticClass:"col-12 text-left"},[_c('p',{staticClass:"titulo"},[_vm._v(" VALOR TOTAL: "),_c('b',[_vm._v(_vm._s(_vm._f("currency")(_vm.loteComp.info ? _vm.loteComp.info.valorTotal : 0,'R$', 2, { spaceBetweenAmountAndSymbol: true, decimalSeparator: ',', thousandsSeparator: '.', })))])])]),(_vm.btn)?_c('div',{staticClass:"col-12 mt-2"},[_c('button',{staticClass:"card-btn",class:_vm.loteComp.statusPagamento == 'PAGO' &&
          _vm.loteComp.statusAgendamento == 'AGENDADO'
            ? 'detalhe-btn'
            : _vm.loteComp.statusPagamento == 'PAGO'
            ? 'agendar-btn'
            : 'pagar-btn',attrs:{"disabled":(_vm.loteComp.statusPagamento != 'PAGO' && !_vm.loteComp.faturaToken) ||
          (_vm.loteComp.statusPagamento == 'PAGO' && !_vm.agendamentoLiberado)},on:{"click":_vm.agendar}},[_vm._v(" "+_vm._s(_vm.loteComp.statusPagamento == 'PAGO' && _vm.loteComp.statusAgendamento == 'AGENDADO' ? 'VER AGENDAMENTO' : _vm.loteComp.statusPagamento == 'PAGO' ? 'AGENDAR RETIRADA' : 'VER FATURA')+" ")])]):_vm._e()])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }