var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dash-pro-item mb-30 dashboard-widget imprimir"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mb-5",attrs:{"src":_vm.logo,"width":"220"}})]),_c('div',{staticClass:"header"},[_c('h4',{staticClass:"title"},[_vm._v("Comprovante do agendamento")]),_c('a',{staticClass:"printer-btn",attrs:{"javascript:void(0)":"","title":"Imprimir"},on:{"click":_vm.imprimir}},[_c('i',{staticClass:"fa fa-print"})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-9"},[_c('span',{staticClass:"destaque"},[_vm._v(_vm._s(_vm.agendamento.agendamentoId))])])]),_c('div',{staticClass:"row"},[_vm._m(1),_c('div',{staticClass:"col-9"},[_c('span',[_vm._v(_vm._s(_vm.agendamento.leilao))])])]),_c('div',{staticClass:"row"},[_vm._m(2),_c('div',{staticClass:"col-9"},[_c('span',[_vm._v(_vm._s(_vm.agendamento.numeroLote))])])]),_c('div',{staticClass:"row"},[_vm._m(3),_c('div',{staticClass:"col-9"},[_c('span',[_vm._v(_vm._s(_vm.agendamento.descricaoLote))])])]),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-9"},[_c('span',[_vm._v(_vm._s(_vm.agendamento.localEndereco))])])]),_c('div',{staticClass:"row"},[_vm._m(5),_c('div',{staticClass:"col-9"},[_c('span',[_vm._v(_vm._s(_vm.agendamento.dataAgendamento))])])]),_vm._m(6),_c('div',{staticClass:"col-12"},[(_vm.mensagemErro)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" "+_vm._s(_vm.mensagemErro)+" ")]):_vm._e()])])]),_c('div',{staticClass:"row mt-3 imprimir-btn"},[_c('div',{staticClass:"col-12 text-center mt-2"},[_c('Button',{staticClass:"mr-2",attrs:{"desc":"Voltar","width":45,"disabled":_vm.loading,"tela":"lotes"}}),_c('Button',{attrs:{"desc":_vm.width < 800 ? 'cancelar' : 'cancelar agendamento',"width":45,"click":_vm.cancelar,"loading":_vm.loading,"disabled":_vm.loading}})],1),_c('div',{staticClass:"col-12 col-md-6"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',{staticClass:"destaque"},[_vm._v("Protocolo: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',[_vm._v("Leilão: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',[_vm._v("Lote: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',[_vm._v("Descrição: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',[_vm._v("Local: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3"},[_c('span',[_vm._v("Data: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"separador"}),_c('p',[_vm._v("Atenção!")]),_c('p',[_vm._v(" Verifique a documentação exigida no edital, parágrafo 'RETIRADA DOS LOTES'. ")])])])
}]

export { render, staticRenderFns }